// Refer to https://github.com/sergiodxa/remix-auth-form for more information
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const LoginActionSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
});

export type LoginActionFormData = z.infer<typeof LoginActionSchema>;

export const LoginActionResolver = zodResolver(LoginActionSchema);
